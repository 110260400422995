import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import OldCard from "../components/OldCard";
import CustomLink from "../components/CustomLink";
import Loader from "../partials/Loader";
import CustomButtonLink from "../components/CustomButtonLink";
import Markdown from "react-markdown";
import CustomButton from "../components/CustomButton";
import RsvpBar from "../components/RsvpBar";
import { WarningCircle } from "@phosphor-icons/react";
import { SessionUser } from "../lib/SessionUser";

const SocialExperienceCheckout = (props) => {
  if (!SessionUser.isLoggedIn()) {
    const parts = window.location.href.split("/social_experience");
    window.location.href = "/login?checkout=" + parts[1];
  }

  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [gst, setGst] = useState(0);
  const [maxTicketsAvailable, setMaxTicketsAvailable] = useState(10);
  const [userName, setUserName] = useState(SessionUser.userProfile().name);
  const [userEmail, setUserEmail] = useState(SessionUser.userProfile().email);
  const [userMobile, setUserMobile] = useState(
    SessionUser.userProfile().mobile || ""
  );
  const [checkoutError, setCheckoutError] = useState(false);
  let params = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let id = params.id;
    if ([undefined, null, ""].includes(id)) {
      window.location.href = "/";
    }
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const { data, _status } = await axiosInstance.get(
        `/public/social_experiences/${id}.json`,
        {}
      );
      const bookingDetails = data.data.booking_details;
      const maxAvailable =
        bookingDetails.free_max_booking > bookingDetails.free_available_seats
          ? bookingDetails.free_available_seats
          : bookingDetails.free_max_booking;
      setEvent(data.data);
      setIsLoading(false);
      setMaxTicketsAvailable(maxAvailable);
    } catch (err) {
      console.log(err);
      // window.location.href = "/";
    }
  };

  const updateRsvpCount = (e) => {
    const newTicketCount =
      ticketsCount + parseInt(e.target.dataset.ticketCount);
    if (ticketsCount >= 0 && ticketsCount <= maxTicketsAvailable) {
      const newTotal = newTicketCount * event.price;
      const newGst = 0.18 * newTotal;
      setTicketsCount(newTicketCount);
      setSubTotal(newTotal);
      setGst(newGst);
    }
  };

  return (
    <div className="event-checkout-container h-100">
      {isLoading && (
        <div style={{ margin: "58% 0" }}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          {checkoutError && (
            <div className="alert alert-danger">
              <WarningCircle
                size={20}
                weight="fill"
                style={{ color: "#AF4524", marginRight: "5px" }}
              />
              There has been an issue reserving tickets
            </div>
          )}
          <div className="d-flex rsvp-select-tickets">
            <div className="d-flex me-auto rsvp-label">Number of Guest(s)</div>
            <div className="d-flex ms-auto">
              {ticketsCount === 0 && (
                <CustomButton
                  className="btn primary-btn"
                  buttonId="InitiateRsvpButton"
                  clickEvent={updateRsvpCount}
                  otherProps={{ "data-ticket-count": 1 }}
                >
                  Add
                </CustomButton>
              )}
              {ticketsCount > 0 && (
                <div className="ticket-counter-container d-flex">
                  <CustomButton
                    className={`d-flex btn primary-btn ticket-counter-change ${
                      ticketsCount === 0 ? "disabled" : ""
                    }`}
                    buttonId="InitiateRsvpButton"
                    clickEvent={updateRsvpCount}
                    otherProps={{ "data-ticket-count": -1 }}
                  >
                    -
                  </CustomButton>
                  {ticketsCount < maxTicketsAvailable && (
                    <span className="d-flex ticket-count">{ticketsCount}</span>
                  )}
                  {ticketsCount === maxTicketsAvailable && (
                    <span className="d-flex ticket-count">
                      <span>{ticketsCount}</span>
                      <span>max</span>
                    </span>
                  )}
                  <CustomButton
                    className={`d-flex btn primary-btn ticket-counter-change ${
                      ticketsCount === maxTicketsAvailable ? "disabled" : ""
                    }`}
                    buttonId="InitiateRsvpButton"
                    clickEvent={updateRsvpCount}
                    otherProps={{ "data-ticket-count": 1 }}
                  >
                    +
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
          {ticketsCount > 0 && (
            <div className="rsvp-user-information">
              <div className="form-control-row">
                <label htmlFor="userName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={userName}
                  id="userName"
                  placeholder="Enter your name"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="form-control-row">
                <label htmlFor="userEmail" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={userEmail}
                  id="userEmail"
                  placeholder="Enter your email"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
              <div className="form-control-row">
                <label htmlFor="user" className="form-label">
                  Mobile
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={userMobile}
                  id="userMobile"
                  placeholder="Enter your mobile"
                  onChange={(e) => setUserMobile(e.target.value)}
                />
              </div>
            </div>
          )}
          {ticketsCount > 0 && event.price > 0 && (
            <div className="rsvp-total-information">
              <div className="d-flex sub-total-price">
                <div className="d-flex me-auto">Subtotal</div>
                <div className="d-flex ms-auto">&#x20B9; {subTotal}</div>
              </div>
              <div className="d-flex sub-total-price">
                <div className="d-flex me-auto">Tax (incl GST)</div>
                <div className="d-flex ms-auto">
                  &#x20B9; {Math.round(gst * 100) / 100}
                </div>
              </div>
              <div className="d-flex total-price">
                <div className="d-flex me-auto">Total</div>
                <div className="d-flex ms-auto">&#x20B9; {subTotal + gst}</div>
              </div>
            </div>
          )}
          {ticketsCount > 0 && event.terms_and_conditions && (
            <div className="rsvp-terms">
              <h3>Terms and Conditions</h3>
              {event.terms_and_conditions}
            </div>
          )}
          {ticketsCount > 0 && (
            <RsvpBar
              mode="checkout"
              ticketCount={ticketsCount}
              price={subTotal + gst}
              event={event}
              eventId={event.id}
              eventSlotId={event.booking_details.experience_slot_id}
              userParams={{
                name: userName,
                email: userEmail,
                mobile: userMobile,
              }}
              showCheckoutError={setCheckoutError}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SocialExperienceCheckout;
