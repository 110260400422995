import logo from "../assets/images/logo.png";
import { X } from "@phosphor-icons/react";
import CustomButton from "../components/CustomButton";
import googleSsoImage from "../assets/images/google-sso-logo.png";
import modalSuccess from "../assets/images/modal-success.png";
import modalFailure from "../assets/images/modal-error.png";
import { signInWithGooglePopup } from "../lib/firebase.util";
import { Modal } from "bootstrap";
import axios from "axios";
import {useEffect, useState} from "react";
import {publish} from "../lib/event";

const UserLoginModal = () => {
    const [viewState, setViewState] = useState('defaultView');

    const googleLogin = async (e) => {
        try {
            const response = await signInWithGooglePopup();
            const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
            const url = `/public/users/sign_in.json`;
            const data = { name: response.user.displayName, email: response.user.email };
            const apiResponse = (await axiosInstance.post(url, data, {})).data;
            setViewState('success');
            localStorage.setItem('user', JSON.stringify(apiResponse.data));
            publish('userLoggedIn');
            hideModal();
        } catch (err) {
            setViewState('failure');
            hideModal();
        }
    }

    const hideModal = () => {
        setTimeout(() => {
            document.getElementById("loginModal").classList.remove("in");
            document.getElementById("loginModal").classList.remove("show");
            document.getElementsByClassName("modal-backdrop")[0].remove();
            setViewState('defaultView');
        }, 3000);
    }

    return (
        <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="loginModelLabal"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header d-flex">
                        <div className='me-auto logo-container'><img src={logo} width={102}/></div>
                        <X size="16" weight='bold' data-bs-dismiss="modal" aria-label="Close" className='close-btn'/>
                    </div>
                    <div className="modal-body text-center">
                        {
                            viewState === 'defaultView' &&
                            <>
                                <p id='loginModalLoginReasonText'></p>
                                <CustomButton clickEvent={googleLogin} buttonId='GoogleSSOButton' className='btn primary-btn sso-btn'>
                                    <img src={googleSsoImage} /> Sign in with Google
                                </CustomButton>
                            </>
                        }
                        {
                            viewState === 'success' &&
                            <>
                                <img style={{ marginTop: '20px' }} src={modalSuccess} />
                                <p style={{ marginBottom: '50px', marginTop: '20px' }}>Login Successful</p>
                            </>
                        }
                        {
                            viewState === 'failure' &&
                            <>
                                <img style={{ marginTop: '20px' }} src={modalFailure} />
                                <p style={{ marginBottom: '50px', marginTop: '20px' }}>Login Failed</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserLoginModal;