import React, {useEffect, useState} from "react";
import CustomLink from "../components/CustomLink";
import CustomButtonLink from "../components/CustomButtonLink";
import { Modal } from "bootstrap";
import { publish } from "../lib/event";
import profileImage from "../assets/images/profile-image.png";
import {SignOut} from "@phosphor-icons/react";
import {subscribe} from "../lib/event";
import {SessionUser} from "../lib/SessionUser";

const Sidebar = () => {

    useEffect(() => {
        subscribe('userLoggedIn',() => {});
    }, []);

    const openLoginModal = (e) => {
       const modal = new Modal('#loginModal');
       // TODO: [Hack] need to remove this when we move to a full page implementation
       document.getElementById('loginModalLoginReasonText').innerHTML = 'Login/Sign-Up using';
       modal.show();
       publish('closeMenu');
    }

    const manageAccount = (e) => {}

    const logOut = (e) => {
        SessionUser.logOut();
        window.location.href = "/";
    }

    return (
        <>
            <div className='side-menu'>
                {/*<h3>Menu</h3>*/}
                {/*{*/}
                {/*    user &&*/}
                {/*    <div className='d-flex profile-container'>*/}
                {/*        <img src={profileImage}/>*/}
                {/*        <div className='profile-details'>*/}
                {/*            <span className='profile-name'>{user.user.name}</span>*/}
                {/*            <CustomButtonLink className='manage-account-link' linkId='ManageAccountLink' clickEvent={manageAccount}>Manage Account</CustomButtonLink>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                <ul>
                    {
                        !SessionUser.isLoggedIn() &&
                        <li>
                            <CustomLink href='login' linkId='UserLoginLink' className=''>
                                Login / Signup
                            </CustomLink>
                        </li>
                    }
                    <li><CustomLink href='archive' linkId='ArchiveLink' className=''>Archives</CustomLink></li>
                    {/*<li><a href='https://puttingscene.framer.website/' target='_blank' rel='noreferrer'>Blogs</a></li>*/}
                    <li><CustomLink href='https://forms.gle/B3H62vCv7SVPfvCM7' linkId='PartnerWithUsLink' className=''>Get Featured</CustomLink></li>
                    <li><CustomLink href='https://venn.buzz/contact-us' linkId='ArchiveLink' className=''>Contact Us</CustomLink></li>
                </ul>
                {
                    SessionUser.isLoggedIn() &&
                    <CustomButtonLink linkId="LogoutLink" className='logout-link' clickEvent={logOut}>
                        <SignOut size={24} />
                        Logout
                    </CustomButtonLink>

                }
            </div>
            <div className='side-menu-bg'></div>
        </>
)
    ;
}

export default Sidebar;