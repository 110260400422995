import CustomLink from "./CustomLink";
import React from "react";
import CustomButton from "./CustomButton";
import {Modal} from "bootstrap";
import {subscribe} from "../lib/event";
import axios from "axios";
import useRazorpay from "react-razorpay";
import {SessionUser} from "../lib/SessionUser";

const RsvpBar = ({mode, ticketCount, price, eventId, availableTickets, userParams, showCheckoutError, eventSlotId, event}) => {
    const [Razorpay] = useRazorpay();

    const getPriceDetails = () => {
        if(mode === 'rsvp' && event.booking_details.rsvp_enabled) {
            return (
                <div className='me-auto d-flex price-details'>
                    <span>Ticket Price</span>
                    <h3 dangerouslySetInnerHTML={{__html: isFreeEvent() ? `Free` : `&#x20B9; ${price}/person`}} />
                </div>
            );
        } else if (mode === 'checkout') {
            if(isFreeEvent()) {
                return(
                    <div className='me-auto d-flex price-details'>
                        <span>Total Cost</span>
                        <h3>Free</h3>
                    </div>
                );
            }
            return (
                <div className='me-auto d-flex price-details'>
                    <span>Total Cost</span>
                    <h3>&#x20B9; {price}</h3>
                </div>
            );
        }
    }

    const rsvp = () => {
        const checkoutLink = `social_experience/${eventId}/checkout`;
        window.location.href = `${window.location.origin}/${checkoutLink}`;
        // if(!user) {
        //     const modal = new Modal('#loginModal');
        //     // TODO: [Hack] need to remove this when we move to a full page implementation
        //     document.getElementById('loginModalLoginReasonText').innerHTML = 'You need to login to RSVP for this event';
        //     modal.show();
        //     subscribe('userLoggedIn',() => {
        //         window.location.href = `${window.location.origin}/${checkoutLink}`;
        //     });
        // } else {
        //     window.location.href = `${window.location.origin}/${checkoutLink}`;
        // }
    }

    const openEvent = () => {
        window.open(event.shortened_link);
    }

    const isFreeEvent = () => {
        return price <= 0;
    }

    const payNow = async () => {
        const errorHandler = () => {
            document.getElementById('errorScreenText').innerHTML = 'Event RSVP not successful';
            document.getElementById('mainContainer').classList.add('error-alert-open');
            setTimeout(() => {
                document.getElementById('errorScreenText').innerHTML = '';
                document.getElementById('mainContainer').classList.remove('error-alert-open');
                showCheckoutError(true);
            }, 3000);
        }
        const successHandler = (data) => {
            document.getElementById('successScreenText').innerHTML = 'Event RSVP successful';
            document.getElementById('mainContainer').classList.add('success-alert-open');
            setTimeout(async () => {
                const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
                const loginResponse = await axiosInstance.get(
                    `/user/profile.json`,
                    { headers: { 'Authorization': SessionUser.userToken() } }
                );
                SessionUser.updateProfile(loginResponse.data.data);
                document.getElementById('successScreenText').innerHTML = '';
                document.getElementById('mainContainer').classList.remove('success-alert-open');
                window.location.href = `${window.location.origin}/my_social_experience/${data.order.order_id}`;
            }, 3000);
        }

        const orderParams = {
            total_seats: ticketCount, user_name: userParams.name, user_email: userParams.email, user_mobile: userParams.mobile, experience_id: eventId, experience_slot_id: eventSlotId
        };
        window.scrollTo(0, 0);
        try {
            const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
            const {data, _status} = await axiosInstance.post(
                `/user/bookings.json`,
                orderParams,
                { headers: { 'Authorization': SessionUser.userToken() } }
            );
            if(data.success) {
                const order = data.data.order;
                const user = data.data.order.user;
                const event = data.data.event;
                if (price > 0) {
                    const options = {
                        key: order.payment_config.razorpay_key,
                        amount: `${order.amount * 100}`,
                        currency: order.payment_config.currency,
                        name: order.payment_config.company_name,
                        description: event.description,
                        image: order.payment_config.image_url,
                        order_id: order.payment.order_id,
                        handler: async function (response) {
                            const paymentConfirmation = {
                                payment_id: response.razorpay_payment_id,
                                order_id: response.razorpay_order_id,
                                signature: response.razorpay_signature
                            };
                            const {data, _status} = await axiosInstance.patch(
                                `/user/bookings/${order.order_id}/pay.json`,
                                paymentConfirmation,
                                { headers: { 'Authorization': SessionUser.userToken() } }
                            );
                            if(data.success) { successHandler(data.data); }
                        },
                        modal: {
                            ondismiss: () => { errorHandler(); }
                        },
                        prefill: {
                            name: user.name,
                            email: user.email,
                            contact: user.mobile,
                        },
                        theme: { color: order.payment_config.theme },
                    };
                    const rzp1 = new Razorpay(options);
                    rzp1.on('payment.failed', (response) => { console.log(response); errorHandler(); });
                    rzp1.open();
                } else {
                    successHandler(data.data);
                }
            } else {
                errorHandler();
            }
        } catch(err) {
            console.log(err);
            // errorHandler();
        }
    }

    const getRsvpAction = () => {
        if(event.booking_details.rsvp_enabled) {
            if(mode === 'rsvp') {
                if(availableTickets > 0) {
                    return <CustomButton buttonId='RsvpLink' className='ms-auto d-flex btn primary-btn' clickEvent={rsvp}>RSVP</CustomButton>;
                } else {
                    return <span className='ms-auto d-flex btn primary-btn disabled'>Sold Out</span>;
                }
                return <CustomButton buttonId='RsvpLink' className={`ms-auto d-flex btn primary-btn ${availableTickets === 0 ? 'disabled' : ''}`} clickEvent={rsvp}>RSVP</CustomButton>;
            } else if (mode === 'checkout') {
                return <CustomButton buttonId='PayNowButton' clickEvent={payNow} className='btn primary-btn'>
                    { isFreeEvent() ? 'RSVP' : 'Pay Now' }
                </CustomButton>;
            }
        } else {
            return <CustomButton buttonId='RsvpLink' className='ms-auto d-flex btn primary-btn' clickEvent={openEvent}>Know More Details</CustomButton>;
        }
    }

    return (
        <div className={`d-flex rsvp-details ${event.booking_details.rsvp_enabled ? '' : 'non-hosted'}`}>
            {event.booking_details.rsvp_enabled && getPriceDetails()}
            {getRsvpAction()}
        </div>
    );
}

export default RsvpBar;