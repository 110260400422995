import loaderData from "../assets/lotties/loader.json";
import Lottie from "react-lottie";
import React from "react";

const Loader = () => {
    const loaderOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderData,
        rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
    };

    return <Lottie options={loaderOptions} height={56} width={56} />
}

export default Loader;