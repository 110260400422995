import {Mixpanel} from "../lib/Mixpanel";
import {useLocation} from "react-router-dom";
import {getPresentRoute} from "../lib/RouteHelper";

const CustomButton = ({className, buttonId, children, clickEvent, otherProps}) => {
    const location = useLocation();
    const clickEventHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { route, _routeParams } = getPresentRoute(location.pathname)
        Mixpanel.track(`Web::${route.name}::${buttonId}::Click`)
        clickEvent(e);
    }

    return <button className={className} onClick={clickEventHandler} {...otherProps}>{children}</button>;
}

export default CustomButton;