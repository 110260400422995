import { useState, useEffect } from "react";

export const ImageCarousal = ({ images, expired, children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const handleTouchStart = (e) => {
    // e.stopPropagation();
    // e.preventDefault();
    // console.log(e, "touchstart");
    const touchStartX = e.touches[0].clientX;
    setTouchStartX(touchStartX);
  };
  const handleTouchEnd = (e) => {
    // e.stopPropagation();
    // e.preventDefault();
    const touchEndX = e.changedTouches[0].clientX;
    const difference = touchStartX - touchEndX;
    if (difference > 50) {
      // Swipe left
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    } else if (difference < -50) {
      // Swipe right
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  return (
    <div
      className={`card-img-top ${expired ? "expired" : ""}`}
      style={{
        backgroundImage: `url(${images[currentIndex].url})`,
        display: "flex",
      }}
      alt={images[currentIndex].url}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
    >
      {children}
      {images.map((_, index) => (
        <div
          className="image-counter"
          style={{
            backgroundColor: index === currentIndex ? "#000000" : "#CCCCCC",
            left: navigator.canShare
              ? `calc(37% - ${images.length * 7}px)`
              : `calc(50% - ${images.length * 7}px)`,
            margin: "4px 2px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setCurrentIndex(index);
          }}
        ></div>
      ))}
    </div>
  );
};
