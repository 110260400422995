import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import OldCard from "../components/OldCard";
import Loader from "../partials/Loader";
import CustomButtonLink from "../components/CustomButtonLink";
import Markdown from "react-markdown";
import RsvpBar from "../components/RsvpBar";
import moment from "moment";
import EventNotFound from "../assets/images/event_not_found.png";

const SocialExperience = (props) => {
  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEventFound, setIsEventFound] = useState(false);
  let params = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const expandDescription = (e) => {
    e.target.parentElement.classList.remove("clipped");
    setTimeout(() => {
      e.target.parentElement.classList.add("clipped");
    }, 20000);
  };

  const formatBackendDate = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:SS.SSSZ");
  };

  const loadData = async () => {
    let id = params.id;
    if ([undefined, null, ""].includes(id)) {
      window.location.href = "/";
    }
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const { data, _status } = await axiosInstance.get(
        `/public/social_experiences/${id}.json`,
        {}
      );
      setEvent(data.data);
      setIsLoading(false);
      setIsEventFound(true);
    } catch (err) {
      setIsLoading(false);
      setIsEventFound(false);
    }
  };

  const renderEventDetails = () => {
    return event.details.map((detail) => {
      return (
        <div
          className="about-event-container"
          key={`about-event-container-${detail.id}-details`}
        >
          <h3>{detail.heading}</h3>
          <Markdown>{detail.details}</Markdown>
        </div>
      );
    });
  };

  return (
    <div className="event-details-container">
      {isLoading && (
        <div style={{ margin: "58% 0" }}>
          <Loader />
        </div>
      )}
      {!isLoading && isEventFound && (
        <>
          <OldCard
            event={event}
            key={`event-${event.id}-container`}
            showDescription={false}
          />
          <h2 className="event-details-header">Description</h2>
          <p
            className={`event-description ${
              event.long_description.length > 270 ? "clipped" : ""
            }`}
          >
            <span>
              {event.long_description.split("\n").map((line, index) => {
                if (line.startsWith("<li>") && line.endsWith("</li>")) {
                  return (
                    <ul key={index}>
                      <li>
                        {line.slice(4, -5).replace(/\\n|\n|<li>|<\/li>/g, "")}
                      </li>
                    </ul>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      {line.replace(/\\n|\n|<li>|<\/li>/g, "")}
                      {index <
                        event.long_description.split("\n").length - 1 && <br />}
                    </React.Fragment>
                  );
                }
              })}
            </span>
            {event.long_description.length > 270 && (
              <CustomButtonLink
                linkId="ReadMoreLink"
                className="event-description-read-more"
                clickEvent={expandDescription}
              >
                Read More
              </CustomButtonLink>
            )}
          </p>
          {event.details.length > 0 && (
            <>
              <h2 className="event-details-header">About the event</h2>
              {renderEventDetails()}
            </>
          )}
          {formatBackendDate(event.event_date).isSameOrAfter(moment()) && (
            <RsvpBar
              mode="rsvp"
              event={event}
              availableTickets={event.booking_details.free_available_seats}
              price={event.price}
              eventId={event.id}
            />
          )}
        </>
      )}
      {!isLoading && !isEventFound && (
        <div
          className="no-events-container"
          style={{ position: "relative", left: "inherit" }}
        >
          <img src={EventNotFound} />
          <p>We couldn’t find the event you’re looking for</p>
        </div>
      )}
    </div>
  );
};

export default SocialExperience;
