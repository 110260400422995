import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBMP44vy62xSAq6hY9fVY3XgaJUpXKOFPQ",
    authDomain: "venn-ed02d.firebaseapp.com",
    projectId: "venn-ed02d",
    storageBucket: "venn-ed02d.appspot.com",
    messagingSenderId: "894750476722",
    appId: "1:894750476722:web:a1acd223a82ee1addbbcab",
    measurementId: "G-P0G0D2R1NG"
};
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
