import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import OldCard from "../components/OldCard";
import CustomLink from "../components/CustomLink";
import Loader from "../partials/Loader";
import CustomButtonLink from "../components/CustomButtonLink";
import EventNotFound from "../assets/images/event_not_found.png";
import {SessionUser} from "../lib/SessionUser";

// TODO: Need to add loader
function Share(props) {
    const [event, setEvent] = useState({});
    const [user, setUser] = useState({});
    let params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isEventFound, setIsEventFound] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setIsLoading(true);
            let id = params.id;
            if([undefined, null, ''].includes(id)) {
                window.location.href = "/";
            }
            const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
            const {data, _status} = await axiosInstance.get(`/public/shares/${id}.json`, {});
            setEvent(data.data.event);
            setUser(data.data.user);
            setIsLoading(false);
            setIsEventFound(true);
        } catch(ex) {
            setIsLoading(false);
            setIsEventFound(false);
        }
    }

    return(
        <>
            { isLoading && <div style={{ margin: '58% 0' }}><Loader/></div> }
            {
                !isLoading && isEventFound &&
                <div className="share-container">
                    {
                        !user &&
                        <h3 className='share-title'>Your friend has invited you to check this event</h3>
                    }
                    {
                        user &&
                        <h3 className='share-title'>{user.name} has invited you to check this event</h3>
                    }
                    {
                        Object.keys(event).length &&
                        <OldCard event={event} key={`event-${event.id}-container`} showDescription={true} showMoreDetails={true}/>
                    }

                    {
                        !SessionUser.isLoggedIn() &&
                        <>
                            <p className='text-center mb-1 mt-5'>Get a daily dispatch of what's happening in Bangalore</p>
                            <CustomButtonLink href='/login' className='btn primary-btn bg-white mt-0' buttonId='JoinVennLink'>Join Venn</CustomButtonLink>
                        </>
                    }
                    {
                        SessionUser.isLoggedIn() &&
                        <CustomLink href='?source=sharelink' className='btn primary-btn bg-white' linkId='ViewMoreEventsLink'>See more experiences</CustomLink>
                    }
                </div>
            }
            {
                !isLoading && !isEventFound &&
                <div className='no-events-container' style={{position: 'relative', left: 'inherit'}}>
                    <img src={EventNotFound}/>
                    <p>We couldn’t find the event you’re looking for</p>
                </div>
            }
        </>
    );
}

export default Share;