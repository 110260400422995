import React, {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {Helmet} from "react-helmet-async";
import OldCard from "../components/OldCard";
import NoEvents from '../assets/images/no_events.png';
import OnBreak from '../assets/images/on_break.png';
import CustomButton from "../components/CustomButton";
import Loader from "../partials/Loader";
import {SessionUser} from "../lib/SessionUser";

function Home(props) {
    const [dayFilter, setDayFilter] = useState('new');
    const [data, setData] = useState([]);
    const [expiredData, setExpiredData] = useState([]);
    const [laterInWeekData, setlaterInWeekData] = useState([]);
    const [showNoData, setShowNoData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isOnBreak, setIsOnBreak] = useState(process.env.REACT_APP_ENABLE_BREAK === 'true');
    const headers = { today: "Today", tomorrow: "Tomorrow", later: "Later In The Week", new: 'Today' };

    useEffect(() => {
        // if(!user) { window.location.href = '/login'; }
        if(!isOnBreak) { loadData(''); }
    }, []);

    const loadData = async (filter) => {
        setIsLoading(true);
        const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
        const selectedFilter = filter === '' ? 'all' : filter;
        const url = `/user/social_experiences.json?filter=${selectedFilter}`;
        try {
            const response = (await axiosInstance.get(url, {headers: { Authorization: `${SessionUser.userToken()}` }})).data;
            setDayFilter(response.data.curation.filter);
            const events = response.data.events;
            setShowNoData(events.length === 0);
            if(events.length > 0) {
                if(response.data.curation.filter === 'later') {
                    setData(events);
                    setlaterInWeekData([]);
                    setExpiredData([]);
                } else {
                    setData(events.filter((e) => e.display_section === 'day'));
                    setlaterInWeekData(events.filter((e) => e.display_section === 'later'));
                    setExpiredData(events.filter((e) => e.display_section === 'expired'));
                }
            }
            setIsLoading(false);
        } catch (err) {
            if (err instanceof AxiosError && err.response.status === 401) {
                window.location.href = "/login";
            }
            setShowNoData(true);
            setIsLoading(false);
            console.log(err);
        }
    }

    // const formatBackendDate = date => { return moment(date, 'YYYY-MM-DDTHH:mm:SS.SSSZ')  }

    const renderRows = (listData, expired = false) => {
        if(listData.length) {
            return listData.map((event) => <OldCard expired={expired} event={event} showDescription={true} />);
        } else {
            return <h2 className="no-events-header">No events found.</h2>;
        }
    }

    const selectFilter = async (e) => {
        setDayFilter(e.target.dataset.filter);
        await loadData(e.target.dataset.filter);
    }

    return(
        <>
            <Helmet>
                <html lang="en"/>
                <title>Venn - Events happening in your city</title>
                <meta name="description" content="A daily dispatch of what's happening in Bangalore"/>
            </Helmet>
            <div className='filter-header d-flex mb-2'>
                <h3 className='me-auto p-2 filter-heading'>{headers[dayFilter]}</h3>
            </div>
            {
                !isOnBreak &&
                <div className='header-margin filter-pills'>
                    <CustomButton className={`${dayFilter === 'today' ? 'selected' : ''}`} clickEvent={selectFilter}
                                  buttonId='TodayFilterButton'
                                  otherProps={{'data-filter': 'today'}}>Today</CustomButton>
                    <CustomButton className={`${dayFilter === 'tomorrow' ? 'selected' : ''}`} clickEvent={selectFilter}
                                  buttonId='TomorrowFilterButton'
                                  otherProps={{'data-filter': 'tomorrow'}}>Tomorrow</CustomButton>
                    <CustomButton className={`${dayFilter === 'later' ? 'selected' : ''}`} clickEvent={selectFilter}
                                  buttonId='LaterFilterButton' otherProps={{'data-filter': 'later'}}>Later in the
                        week</CustomButton>
                </div>
            }
            {
                isOnBreak &&
                <div className='no-events-container' style={{position: 'relative', left: 'inherit'}}>
                    <img src={OnBreak}/>
                    <h2>We're on a break</h2>
                    <p dangerouslySetInnerHTML={{__html: process.env.REACT_APP_BREAK_TEXT}}/>
                </div>
            }
            {
                !isOnBreak && isLoading &&
                <div style={{margin: '58% 0'}}>
                    <div style={{margin: '58% 0'}}><Loader/></div>
                </div>
            }
            {
                !isLoading && !showNoData &&
                <>
                <div>
                        <div className="event-card-list">
                            {renderRows(data)}
                        </div>
                    </div>
                    {
                        laterInWeekData.length > 0 &&
                        <div>
                            <h3 className='me-auto p-2 filter-heading' style={{marginBottom: '15px'}}>Later In The
                                Week</h3>
                            <div className="event-card-list">{renderRows(laterInWeekData)}</div>
                        </div>

                    }
                    {
                        expiredData.length > 0 &&
                        <div>
                            <h3 className='me-auto p-2 filter-heading' style={{marginBottom: '15px'}}>Expired</h3>
                            <div className="event-card-list" style={{marginTop: '0px'}}>{renderRows(expiredData, true)}</div>
                        </div>

                    }
                </>
            }
            {
                !isLoading && showNoData &&
                <div className='no-events-container' style={{ position: 'relative', left: 'inherit' }}>
                    <img src={NoEvents}/>
                    <h2>No events found</h2>
                    <p>New list is being curated, we’ll notify you once it’s ready</p>
                </div>
            }
        </>
    );
}

Home.headerTempalte = 'default';
export default Home;