let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
let isUserLoggedIn = Object.keys(user).length > 0;

let userActions = {
    userToken: () => {
        if (isUserLoggedIn) return user.token;
    },
    eventCount: () => {
        return isUserLoggedIn ? user.event_count : 0;
    },
    userProfile: () => {
        if (isUserLoggedIn) return user;
    },
    isLoggedIn: () => {
        return isUserLoggedIn;
    },
    logOut: () => {
        localStorage.removeItem('user');
    },
    updateProfile: (profile) => {
        console.log(profile);
        const data = Object.assign(profile.user, {event_count: profile.upcoming_events});
        localStorage.setItem('user', JSON.stringify(data));
    }
};

export let SessionUser = userActions;