import logo from "../assets/images/logo.png";
import {ArrowLeft, CopySimple, List, ShareNetwork, Ticket, X} from "@phosphor-icons/react";
import {useLocation} from "react-router-dom";
import ShareWithFriends from '../assets/images/share_with_friends.png';
import {useEffect, useState} from "react";
import CustomLink from "../components/CustomLink";
import CustomButton from "../components/CustomButton";
import {Modal} from "bootstrap";
import {subscribe} from "../lib/event";
import {getPresentRoute} from "../lib/RouteHelper";
import {SessionUser} from "../lib/SessionUser";

const Header = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get('source') ? queryParams.get('source') : 'browser';
    const [isMenuOpen, setisMenuOpen] = useState(false);
    const location = useLocation();
    const {route} = getPresentRoute(location.pathname);
    // console.log(session.events);

    useEffect(() => {
        subscribe("closeMenu", () => {
            document.getElementById('mainContainer').classList.remove('menu-open');
            setisMenuOpen(false);
        });
    }, []);
    const getHeaders = () => {
        const {route} = getPresentRoute(location.pathname);
        console.log(route.name);
        switch (route.name) {
            case 'Login':
                return(<></>);
            case 'Archive':
                return backToHomeHeader('Archives');
            case 'Social Experience Details':
                return backToHomeHeader('Experience Details');
            case 'Social Experience Checkout':
                return backToHomeHeader('Complete RSVP');
            case 'Upcoming Experiences':
                return backToHomeHeader('Upcoming Experiences');
            case 'Upcoming Social Experience Details':
                return backToHomeHeader('RSVP Details', 'my_social_experience');
            default:
                return defaultHeader();
        }
    }

    const backToHomeHeader = (text, href = '') => {
        return (
            <div className='page-header page-header-back' style={{padding: '15px'}}>
                <CustomLink href={href} linkId='BackHomeLink' className='d-flex'>
                    <span style={{
                        padding: "8px 9px",
                        borderRadius: "70px",
                        border: "1.4px solid #000",
                        boxShadow: "0.7px 0.7px 0px 0px #000",
                        height: "42px",
                        width: "42px"
                    }}><ArrowLeft size={22} weight="bold"/></span>
                    <h3 style={{
                        fontSize: '24px',
                        padding: '8px 0px',
                        width: "100%",
                        textAlign: "center",
                        marginLeft: "-42px"
                    }}>{text}</h3>
                </CustomLink>
            </div>
        );
    }

    const addFriendModalOpen = (e) => {
        const modal = new Modal('#addFriendModal');
        modal.show();
    }

    const defaultHeader = () => {
        return (
            <>
                <div className='page-header d-flex mb-2'>
                    <div className='d-flex'>
                        <CustomLink href='' linkId='HeaderLogoLink' className='logo p-2 me-auto'>
                            <img width={96} height={32} src={logo} alt='logo'/>
                        </CustomLink>
                        <div>
                            <div className='hamburger d-flex p-2 ms-auto'>
                                {
                                    source === 'browser' &&
                                        <CustomLink href="https://join.puttingscene.com/" className="btn primary-btn"
                                                    linkId="JoinPuttingSceneLink">Join Putting Scene!</CustomLink>
                                }
                                {
                                    source === 'announcement' &&
                                        <CustomButton className='btn primary-btn' clickEvent={addFriendModalOpen}
                                                      buttonId='AddFriendButton'>Add a friend</CustomButton>
                                }
                                {
                                    SessionUser.eventCount() > 0 &&
                                    <CustomLink linkId='upcomingEventsIconLink' className={`upcoming-events-icon ${route.name === 'Home' ? 'close' : 'open'}`} href='my_social_experience'>
                                        <Ticket size={20} />
                                    </CustomLink>
                                }
                                {generateMenuButton()}

                            </div>
                        </div>

                    </div>
                    {
                        SessionUser.eventCount() > 0 && route.name === 'Home' &&
                        <CustomLink className='upcoming-events d-flex' linkId='UpcomingEventsLink'
                                    href='my_social_experience'>
                            <div className='d-flex me-auto'>View Upcoming Events</div>
                            <div className='d-flex ms-auto'>
                                <span className='upcoming-events-counter'>
                                    {SessionUser.eventCount()}
                                </span>
                            </div>
                        </CustomLink>
                    }
                </div>
            </>
        );
    }

    const generateMenuButton = () => {
        const buttonClassName = `menu-${isMenuOpen ? 'close' : 'open'}-button-container`;
        return (
            <CustomButton className={buttonClassName} clickEvent={menuToggle}
                          buttonId={isMenuOpen ? 'MenuCloseButton' : 'MenuOpenButton'}>
                {!isMenuOpen && <List size={20}/>}
                {isMenuOpen && <X size={20}/>}
            </CustomButton>
        );
    }

    const menuToggle = (e) => {
        if (!isMenuOpen) {
            document.getElementById('mainContainer').classList.add('menu-open');
        } else {
            document.getElementById('mainContainer').classList.remove('menu-open');
        }
        setisMenuOpen(!isMenuOpen);
    }
    return getHeaders();
}

export default Header;