import logo from "../assets/images/logo.png";
import {CopySimple, ShareNetwork, X} from "@phosphor-icons/react";
import ShareWithFriends from "../assets/images/share_with_friends.png";
import CustomButton from "../components/CustomButton";

const AddFriendModal = () => {
    const copyUrl = (e) => {
        const element = document.getElementById('addFriendCopyUrlContainer').firstElementChild;
        const url = element.value;
        element.select();
        element.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(url);
        document.getElementById('addFriendModalAlert').style.display = 'block';
        setTimeout(() => {
            document.getElementById('addFriendModalAlert').style.display = 'none';
        }, 6000);
    }

    const shareUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const element = document.getElementById('addFriendCopyUrlContainer').firstElementChild;
        const url = element.value;
        const eventDetails = {
            title: "Your friend has invited you to join PuttingScene",
            text: "Your friend has invited you to join PuttingScene",
            url: url
        };
        if (navigator.share) { navigator.share(eventDetails); }
        return false;
    }

    return (
        <div className="modal fade" id="addFriendModal" tabIndex="-1" aria-labelledby="addFriendModalLabel"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className='alert alert-dark' id='addFriendModalAlert'>Copied to clipboard</div>
                <div className="modal-content">
                    <div className="modal-header d-flex">
                        <div className='me-auto logo-container'><img src={logo} width={102}/></div>
                        <X size="16" weight='bold' data-bs-dismiss="modal" aria-label="Close" className='close-btn'/>
                    </div>
                    <div className="modal-body text-center">
                        <img src={ShareWithFriends}/>
                        <p>Invite your friends to Putting Scene</p>
                        <div className="input-group mb-3" id='addFriendCopyUrlContainer'>
                            <input type="text" className="form-control" disabled value='https://bit.ly/3SQAHRA'/>
                            <CustomButton className="input-group-text" buttonId="AddFriendModal::CopyButton" clickEvent={copyUrl}>
                                <CopySimple size='24'/>
                            </CustomButton>
                        </div>
                        {
                            navigator.canShare &&
                            <CustomButton className='btn primary-btn' buttonId="AddFriendModal::ShareButton" clickEvent={shareUrl}>
                                Share Invite
                                <ShareNetwork size='16'/>
                            </CustomButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddFriendModal;