import React, {useEffect, useState, Component} from "react";
import axios from "axios";
import moment from "moment/moment";
import {Helmet} from "react-helmet-async";
import {CaretLeft, CaretRight} from "@phosphor-icons/react";
import OldCard from "../components/OldCard";
import CustomButton from "../components/CustomButton";
import Loader from "../partials/Loader";
import {SessionUser} from "../lib/SessionUser";

const Archive = () => {
    const [date, setDate] = useState('');
    const [data, setData] = useState([]);
    const [curationList, setCurationList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // if(!user) { window.location.href = '/login'; }
        const archiveDate = moment().subtract(1, 'd').startOf('day');
        loadData(archiveDate.format('DD/MM/YYYY'));
    }, []);

    const loadData = async (date) => {
        setIsLoading(true);
        const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL})
        setDate(date);
        const url = `/user/social_experiences.json?filter=archive&date=${date}`;
        const response = (await axiosInstance.get(url, {headers: { Authorization: `${SessionUser.userToken()}` }})).data;
        setIsLoading(false);
        if(response.data.events.length === 0) {
            await loadData(response.data.archive[0]);
        }
        else {
            setData(response.data.events);
            setCurationList(response.data.archive);
        }
    }

    const hasDate = (indexChange) => {
        return (fetchCurationListIndex(indexChange) !== -1);
    }

    const fetchCurationListIndex = (indexChange) => {
        if(![1,-1].includes(indexChange)) { return -1; }
        const presentIndex = curationList.indexOf(date);
        if(indexChange === 1 && (presentIndex + 1) <= curationList.length -1) {
            return presentIndex + 1;
        }
        else if(indexChange === -1 && (presentIndex - 1) >= 0) {
            return presentIndex - 1;
        }
        else return -1;
    }

    const prevDate = (e) => {
        return moveDate(1);
    }

    const nextDate = (e) => {
        return moveDate(-1);
    }


    const moveDate = async (indexChange) => {
        const ix = fetchCurationListIndex(indexChange);
        if(ix !== -1) { await loadData(curationList[ix]); }
    }

    const renderRows = (listData) => {
        if(listData.length) {
            return listData.map((event) => <OldCard event={event} key={`event-${event.id}-container`} showDescription={true} />);
        } else {
            return (<h2 className="no-events-header">No events found.</h2>);
        }
    }

    const getArchiveDate = () => {
        const archiveDate = moment(date, 'DD/MM/YYYY');
        return (
            <span>
                {archiveDate.format('D')}
                <sup>{archiveDate.format('Do').replace(archiveDate.format('D'), '')}&nbsp;</sup>
                {archiveDate.format('MMM')}
            </span>
        )
    }

    return (
        <>
            <Helmet>
                <html lang="en"/>
                <title>Venn - Events happening in your city</title>
                <meta name="description" content="A daily dispatch of what's happening in Bangalore"/>
            </Helmet>
            <div className='filter-header d-flex mb-2'>
                <h3 className='me-auto p-2 filter-heading'>Events curated on</h3>
                <span className='archive-date-switcher-container d-flex'>
                    <CustomButton className={`d-flex ms-auto archive-date-button left ${ hasDate(1) ? '' : 'disabled' }`} clickEvent={prevDate} buttonId='PrevButton'>
                        <CaretLeft size={10} weight="bold" />
                    </CustomButton>
                    <span className='d-flex' style={{ alignItems: 'center', marginTop: '2px' }}>{getArchiveDate()}</span>
                    <CustomButton className={`d-flex archive-date-button right ${ hasDate(-1) ? '' : 'disabled' }`} clickEvent={nextDate} buttonId='NextButton'>
                        <CaretRight size={10} weight="bold"  />
                    </CustomButton>
                </span>
            </div>
            { isLoading && <div style={{ margin: '58% 0' }}><Loader/></div> }
            {!isLoading && <div className="event-card-list header-margin">{renderRows(data)}</div>}

        </>
    );
}

export default Archive;